import * as actionTypes from '../types/delegationTypes';
import delegation from '../initialStates/delegationInitialState';

const delegationReducer = (state = delegation, action) => {
  switch (action.type) {
    case actionTypes.START_ONE_WALLET_HANDSHAKE:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.FINISH_ONE_WALLET_HANDSHAKE:
      return {
        ...state,
        isLoading: false,
        isOneWalletHandshakeDone: true,
      };
    case actionTypes.LOAD_MAIN_PAGE_STATE:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.LOAD_MAIN_PAGE_STATE_SUCCESS:
      return {
        ...state,
        ...action.mainPageState,
        isLoading: false,
      };
    case actionTypes.LOAD_ACCOUNTS_DELEGATION_STATE:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.LOAD_ACCOUNTS_DELEGATION_STATE_SUCCESS:
      return {
        ...state,
        ...action.accountsDelegationState,
        isLoading: false,
      };
    case actionTypes.LOAD_ACCOUNT_HISTORY_STATE:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.LOAD_ACCOUNT_HISTORY_STATE_SUCCESS:
      return {
        ...state,
        curAccountHistoryPageDetails: action.accountHistoryPageDetails,
        isLoading: false,
      };
    case actionTypes.SHOW_TRANSACTION_MODAL_SUCCESS:
      return {
        ...state,
        transactionModal: { ...action.transactionModal },
      };
    case actionTypes.SUBMIT_TRANSACTION_MODAL_SUCCESS:
      state.transactionModal.isModalVisible = false;
      return {
        ...state,
      };
    case actionTypes.CANCEL_TRANSACTION_MODAL:
      state.transactionModal.isModalVisible = false;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default delegationReducer;
