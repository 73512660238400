import axios from 'axios';
import { get, last, isEmpty } from 'lodash';
import { queryDelegationInfo } from '@oneledger/ons-sdk/requestGenerator';
import {
  delegateTxOffline,
  undelegateTxOffline,
  delegateRewardReinvestTxOffline,
  delegateRewardWithdrawalTxOffline,
} from '@oneledger/ons-sdk/delegation';
import getOneWalletService from './oneWalletService';
import { envSdkConfig, explorerUrl } from '../constants/onsSdk';
import { formatNumber } from './utils';

async function getExplorerMetrics() {
  const { data: metrics } = await axios.get(`${explorerUrl}/metrics`);

  const topDelegator = get(metrics, 'topDelegators.0');
  const delegations = get(metrics, 'totalDelegationAmount', []);
  const delegationYearlyReturnRate = get(
    metrics,
    'delegationYearlyReturnRate',
    'N/A',
  );
  const chartData = isEmpty(delegations) ? [] : getChartData(delegations);
  const topDelegation = get(topDelegator, 'delegationAmount.activeAmount', 0);
  const delegationPoolSize = isEmpty(delegations)
    ? 0
    : last(delegations)['amount'];

  const result = {
    topDelegation,
    topDelegationFormatted: formatNumber(topDelegation),
    topDelegatorAddress: get(topDelegator, 'address'),
    delegationPoolSize,
    delegationPoolSizeFormatted: formatNumber(delegationPoolSize),
    delegationYearlyReturnRate,
    chartData,
  };

  return Promise.resolve(result);
}

function getChartData(delegations) {
  return [
    {
      id: 'Main',
      data: delegations.map((delegation) => ({
        x: delegation.time.split('T')[0],
        y: delegation.amount,
        amountFormatted: formatNumber(delegation.amount),
      })),
    },
  ];
}

async function getUserWalletAccounts() {
  const oneWalletService = getOneWalletService();

  return await oneWalletService.getAccounts();
}

async function getDelegationDataByAccountAddress(addrList) {
  const {
    response: { allDelegStats },
  } = await queryDelegationInfo(addrList, envSdkConfig);

  return allDelegStats;
}

async function getAccountsDelegationData() {
  const accounts = await getUserWalletAccounts();
  const oltAccounts = accounts['OLT'];
  const accountAddrList = oltAccounts.map((account) => account.address);
  const delegationStats = await getDelegationDataByAccountAddress(
    accountAddrList,
  );

  const result = {
    totalAccountDelegations: 0,
    accountDelegationStats: [],
  };

  result.accountDelegationStats = delegationStats.map((stat) => {
    const addr = stat.delegationAddress;
    const account = oltAccounts.find((account) => account.address === addr);
    const { delegationActive } = stat.delegationStats;
    const { delegationRewardsActive } = stat.delegationRewardsStats;
    result.totalAccountDelegations += parseFloat(delegationActive);

    return {
      delegationAddress: addr,
      delegationAccount: {
        ...account,
        balanceFormatted: formatNumber(account.balance),
      },
      delegationActive,
      delegationActiveFormatted: formatNumber(delegationActive),
      delegationRewardsActive,
      delegationRewardsActiveFormatted: formatNumber(delegationRewardsActive),
    };
  });

  result.totalAccountDelegationsFormatted = formatNumber(
    result.totalAccountDelegations,
  );

  return result;
}

export const getMainPageState = async () => {
  const accountDelegationData = await getAccountsDelegationData();
  const explorerMetrics = await getExplorerMetrics();

  return { accountDelegationData, explorerMetrics };
};

export const getAccountsDelegationState = async () => {
  const accountDelegationData = await getAccountsDelegationData();

  return { accountDelegationData };
};

export const getAccountHistory = async (accountAddress, page) => {
  const {
    data: { totalPage: totalPages, txs: history },
  } = await axios.get(
    `${explorerUrl}/txs/delegation?delegatorAddresses=["${accountAddress}"]&page=${page}&pagesize=10`,
  );

  return { totalPages, history, accountAddress, curPage: page };
};

export const txOfflineTypes = {
  delegate: 'delegate',
  undelegate: 'undelegate',
  rewardReinvest: 'rewardReinvest',
  rewardWithdrawal: 'rewardWithdrawal',
};

const txOfflineTypeMethodMap = {
  [txOfflineTypes.delegate]: delegateTxOffline,
  [txOfflineTypes.undelegate]: undelegateTxOffline,
  [txOfflineTypes.rewardReinvest]: delegateRewardReinvestTxOffline,
  [txOfflineTypes.rewardWithdrawal]: delegateRewardWithdrawalTxOffline,
};

export const getTxOfflineByType = (txOfflineType, delegateAddress, amount) => {
  const params = { amount: { currency: 'OLT', value: amount + '' } };
  if (txOfflineType === txOfflineTypes.undelegate) {
    params.undelegateAddress = delegateAddress;
  } else {
    params.delegateAddress = delegateAddress;
  }
  return txOfflineTypeMethodMap[txOfflineType](params, envSdkConfig);
};
