const devnet = 'devnet';
const chronos = 'chronos';
const kainos = 'kainos';
const kratos = 'kratos';

export const defaultNetwork = process.env.REACT_APP_NETWORK_NAME || chronos;

export const envRpcUrlMap = {
  [devnet]: 'http://fullnode-sdk.devnet2.oneledger.network/jsonrpc',
  [chronos]: 'https://fullnode-sdk.chronos.oneledger.network/jsonrpc',
  [kainos]: 'https://fullnode-sdk.kainos.oneledger.network/jsonrpc',
  // [kratos]: 'https://fullnode-sdk-test.kratos.oneledger.network/jsonrpc', // update for TEST
  [kratos]: 'https://fullnode-sdk.kratos.oneledger.network/jsonrpc',
};

export const envExplorerUrlMap = {
  [devnet]: 'http://explorer.devnet2.oneledger.network',
  [chronos]: 'https://explorer.chronos.oneledger.network',
  [kainos]: 'https://explorer.kainos.oneledger.network',
  // [kratos]: 'https://explorer-test.kratos.oneledger.network', // test
  [kratos]: 'https://explorer.kratos.oneledger.network',
};

export const envExplorerUiUrlMap = {
  [devnet]: 'http://devnet2.oneledger.network',
  [chronos]: 'https://chronos.oneledger.network',
  [kainos]: 'https://kainos.oneledger.network',
  // [kratos]: 'https://kratos-test.oneledger.network', // test
  [kratos]: 'https://kratos.oneledger.network',
};

const OLT_REMOTE_URL = envRpcUrlMap[defaultNetwork] || envRpcUrlMap[chronos];

// Ons SDK RPC url
export const onsRpcEndpoint = OLT_REMOTE_URL;

// OneLedger explorer api
export const explorerUrl =
  envExplorerUrlMap[defaultNetwork] || envExplorerUrlMap[chronos];

// OneLedger explorer ui url
export const explorerUiUrl =
  envExplorerUiUrlMap[defaultNetwork] || envExplorerUiUrlMap[chronos];

// ONS Store config
export const STORE_CONFIG = {
  platform: 'browser',
  storeLocation: __dirname,
};

export const envSdkConfig = {
  url: onsRpcEndpoint,
  storeConfig: { ...STORE_CONFIG },
};
