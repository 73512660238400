const chromeExtensionId =
  process.env.REACT_APP_CHROME_EXTENSION_ID ||
  'afailfahdcgmdlciggncbankgeeaoklh';

export const appId = 'OneledgerDelegationApp';
export const appKey = '985571';
export const fileOrigin = 'file://';
export const allowedTargetOrigin = `chrome-extension://${chromeExtensionId}`;
export const isRunningFromElectronApp =
  process.env.REACT_APP_IS_FILE_ORIGIN === 'true';
