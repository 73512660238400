export const START_ONE_WALLET_HANDSHAKE =
  'DELEGATION/START_ONE_WALLET_HANDSHAKE';
export const FINISH_ONE_WALLET_HANDSHAKE =
  'DELEGATION/FINISH_ONE_WALLET_HANDSHAKE';
export const LOAD_MAIN_PAGE_STATE = 'DELEGATION/LOAD_MAIN_PAGE_STATE';
export const LOAD_MAIN_PAGE_STATE_SUCCESS =
  'DELEGATION/LOAD_MAIN_PAGE_STATE_SUCCESS';
export const LOAD_ACCOUNTS_DELEGATION_STATE =
  'DELEGATION/LOAD_ACCOUNTS_DELEGATION_STATE';
export const LOAD_ACCOUNTS_DELEGATION_STATE_SUCCESS =
  'DELEGATION/LOAD_ACCOUNTS_DELEGATION_STATE_SUCCESS';
export const LOAD_ACCOUNT_HISTORY_STATE =
  'DELEGATION/LOAD_ACCOUNT_HISTORY_STATE';
export const LOAD_ACCOUNT_HISTORY_STATE_SUCCESS =
  'DELEGATION/LOAD_ACCOUNT_HISTORY_STATE_SUCCESS';
export const SHOW_TRANSACTION_MODAL = 'DELEGATION/SHOW_TRANSACTION_MODAL';
export const SHOW_TRANSACTION_MODAL_SUCCESS =
  'DELEGATION/SHOW_TRANSACTION_MODAL_SUCCESS';
export const SUBMIT_TRANSACTION_MODAL = 'DELEGATION/SUBMIT_TRANSACTION_MODAL';
export const SUBMIT_TRANSACTION_MODAL_SUCCESS =
  'DELEGATION/SUBMIT_TRANSACTION_MODAL_SUCCESS';
export const CANCEL_TRANSACTION_MODAL = 'DELEGATION/CANCEL_TRANSACTION_MODAL';
