import {
  allowedTargetOrigin,
  appId,
  appKey,
  fileOrigin,
  isRunningFromElectronApp,
} from '../constants/ecosystem';

const className = 'OneWalletService';

class OneWalletService {
  constructor(appId) {
    this.appId = appId;
    this.msgIdPromiseHandlersMap = {};
  }

  init() {
    window.addEventListener('message', this._receiveOneWalletMsg.bind(this));
  }

  startHandshake() {
    return this._callOneWalletMethod('handshake');
  }

  getAccounts() {
    return this._callOneWalletMethod('getAccounts');
  }

  signTx(rawTx, password, keyType, keyIndex, publicKey) {
    return this._callOneWalletMethod('signTx', {
      rawTx,
      password,
      keyType,
      keyIndex,
      publicKey,
    });
  }

  // Facially Private Methods
  _callOneWalletMethod(methodName, params) {
    console.log(
      `${className}: calling one wallet method: ${methodName}, with params: ${params}`,
    );
    // Get unique id for this call to ensure that incoming responses are unique for each call
    const msgUniqueKey = this._getMsgUniqueKey();
    const target = isRunningFromElectronApp ? fileOrigin : allowedTargetOrigin;
    const msg = {
      appId,
      appKey,
      msgUniqueKey,
      message: methodName,
      payload: params,
    };

    const result = new Promise((resolve, reject) => {
      this.msgIdPromiseHandlersMap[msgUniqueKey] = {
        resolve,
        reject,
      };

      window.top.postMessage(msg, target);
    });

    return result;
  }

  _receiveOneWalletMsg(e) {
    const { origin, data } = e;

    const { appKey: senderAppKey, appId: senderAppId } = data;
    const isValidCreds = senderAppKey === appKey && senderAppId === appId;
    const isOriginValid =
      origin === allowedTargetOrigin ||
      (isRunningFromElectronApp && origin === fileOrigin);

    if (!isValidCreds) {
      console.error(
        `Got a msg without required credentials: ${JSON.stringify(e)}`,
      );
      console.error('Given data', data);
      return;
    }

    if (!isOriginValid) {
      console.error(`Got a msg without valid origin: ${JSON.stringify(e)}`);
      console.error('Given data', data);
      return;
    }

    if (!this.msgIdPromiseHandlersMap[data.msgUniqueKey]) {
      return;
    }

    if (data.msgResultType === 'SUCCESS') {
      this.msgIdPromiseHandlersMap[data.msgUniqueKey].resolve(data.payload);
    } else {
      this.msgIdPromiseHandlersMap[data.msgUniqueKey].reject(data);
    }
  }

  _getMsgUniqueKey() {
    let uniqueKey = Math.random();

    while (!!this.msgIdPromiseHandlersMap[uniqueKey]) {
      uniqueKey = Math.random();
    }

    return uniqueKey;
  }
}

let oneWalletService;

export default function getOneWalletService() {
  let instance = oneWalletService;

  if (!oneWalletService) {
    instance = new OneWalletService(appId);
    instance.init();
  }

  return instance;
}
