import React, { useState } from 'react';
import '../../../styles/delegationAccountCard.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  showTransactionModal,
  loadAccountHistoryState,
} from '../../../store/actions/delegationActions';
import TransactionModal from '../../../components/common/TransactionModal';
import { useHistory } from 'react-router';
import { txOfflineTypes } from '../../../services/delegationAppService';

const DelegationAccountCard = (props) => {
  const {
    isTransactionModalVisible,
    showTransactionModal,
    loadAccountHistoryState,
    delegationData,
  } = props;
  const history = useHistory();
  const account = delegationData.delegationAccount;
  const [isDelegationBtnLoading, setIsDelegationBtnLoading] = useState(false);
  const [isReinvestBtnLoading, setIsReinvestBtnLoading] = useState(false);

  function getShowTransactionModalMethod(mode, loadingStateFunc) {
    return async (e) => {
      // Prevent click event from propagating up to the card itself which also has a
      // click event attached
      e.preventDefault();
      e.stopPropagation();
      loadingStateFunc(true);
      await showTransactionModal(mode, delegationData);
      loadingStateFunc(false);
    };
  }

  async function goToAccountPage(e) {
    e.preventDefault();
    await loadAccountHistoryState(account.address);
    history.push(`/account/${account.address}`);
  }
  return (
    <>
      <div className="box delegation-account-card">
        <section
          className="media"
          title={'Open account: ' + account.accountName}
          onClick={goToAccountPage}
        >
          <div className="container">
            <div className="columns">
              <div className="column is-3">
                <div className="media-left">
                  <figure className="image is-64x64">
                    <img
                      src="/images/OL_Logo_48px.png"
                      alt={account.accountName}
                    />
                  </figure>
                </div>
              </div>
              <div className="column">
                <div className="media-content">
                  <div className="content">
                    <h2 className="title is-2">{account.accountName}</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="has-text-right row-label align-with-table-btn">
                        Delegated
                      </td>
                      <td
                        className="align-with-table-btn"
                        title={
                          delegationData.delegationActiveFormatted + ' OLT'
                        }
                      >
                        <span className="formatted-amount">
                          {delegationData.delegationActiveFormatted}
                          &nbsp;OLT
                        </span>
                      </td>
                      <td className="has-text-right">
                        {!!+delegationData.delegationAccount.balance && (
                          <button
                            className={`button brand-btn is-rounded is-small
                            ${isDelegationBtnLoading ? 'is-loading' : ''}`}
                            title="Delegate"
                            onClick={getShowTransactionModalMethod(
                              txOfflineTypes.delegate,
                              setIsDelegationBtnLoading,
                            )}
                          >
                            <i className="fas fa-plus"></i>
                          </button>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="has-text-right row-label align-with-table-btn">
                        Rewards
                      </td>
                      <td
                        className="align-with-table-btn"
                        title={
                          delegationData.delegationRewardsActiveFormatted +
                          ' OLT'
                        }
                      >
                        <span className="formatted-amount">
                          {delegationData.delegationRewardsActiveFormatted}
                          &nbsp;OLT
                        </span>
                      </td>
                      <td className="has-text-right">
                        {!!+delegationData.delegationRewardsActive && (
                          <button
                            className={`button brand-btn is-rounded is-small
                            ${isReinvestBtnLoading ? 'is-loading' : ''}`}
                            title="Reinvest"
                            onClick={getShowTransactionModalMethod(
                              txOfflineTypes.rewardReinvest,
                              setIsReinvestBtnLoading,
                            )}
                          >
                            <i className="fas fa-plus"></i>
                          </button>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="has-text-right row-label align-with-table-btn">
                        Balance
                      </td>
                      <td
                        className="align-with-table-btn"
                        title={account.balanceFormatted + ' OLT'}
                      >
                        <span className="formatted-amount">
                          {account.balanceFormatted}
                          &nbsp;OLT
                        </span>
                      </td>
                      <td className="has-text-right"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>

      {isTransactionModalVisible && <TransactionModal />}
    </>
  );
};

DelegationAccountCard.propTypes = {
  isTransactionModalVisible: PropTypes.bool,
  showTransactionModal: PropTypes.func,
  loadAccountHistoryState: PropTypes.func,
  delegationData: PropTypes.object,
};

export default connect(
  (state) => ({
    isTransactionModalVisible: state.delegation.transactionModal.isModalVisible,
  }),
  (dispatch) => ({
    ...bindActionCreators(
      {
        showTransactionModal,
        loadAccountHistoryState,
      },
      dispatch,
    ),
  }),
)(DelegationAccountCard);
