import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import '../../styles/mainPage.scss';
import { loadMainPageState } from '../../store/actions/delegationActions';
import DelegationAccountCard from './views/DelegationAccountCard';
import DelegationChart from './views/DelegationChart';
import { explorerUiUrl } from '../../constants/onsSdk';

const MainPage = (props) => {
  const { accountDelegationData, explorerMetrics, loadMainPageState } = props;

  useEffect(() => {
    loadMainPageState();
  }, [loadMainPageState]);

  function getDelegationAccountCards(accountDelegationData) {
    return accountDelegationData.map((account) => {
      return (
        <div className="column is-one-third" key={account.delegationAddress}>
          <DelegationAccountCard delegationData={account} />
        </div>
      );
    });
  }

  if (!accountDelegationData) {
    return null;
  }

  return (
    <section className="section delegation-page-section">
      <div className="container is-widescreen">
        <h1 className="title">Delegation App</h1>

        <div className="columns">
          <div className="column">
            <nav className="panel is-primary">
              <div className="panel-heading has-text-centered">
                Estimated Yearly Return Rate
              </div>
              <div className="panel-block is-justify-content-center	">
                {explorerMetrics.delegationYearlyReturnRate}
              </div>
            </nav>
          </div>
          <div className="column">
            <nav className="panel is-primary">
              <div className="panel-heading has-text-centered">
                Delegation Pool Size
              </div>
              <div className="panel-block is-justify-content-center	">
                {explorerMetrics.delegationPoolSizeFormatted} OLT
              </div>
            </nav>
          </div>
          <div className="column">
            <nav className="panel is-primary">
              <div className="panel-heading has-text-centered">
                Top Delegator
              </div>
              <div className="panel-block is-justify-content-center	">
                {explorerMetrics.topDelegation ? (
                  <a
                    href={
                      explorerUiUrl +
                      '/account/' +
                      explorerMetrics.topDelegatorAddress
                    }
                    target="_blank"
                    rel="noreferrer"
                    title="Open Top Delegator Account"
                  >
                    {explorerMetrics.topDelegationFormatted} OLT &nbsp;
                    <i className="fas fa-external-link-alt"></i>
                  </a>
                ) : (
                  '0 OLT'
                )}
              </div>
            </nav>
          </div>
          <div className="column">
            <nav className="panel is-primary">
              <div className="panel-heading has-text-centered">
                Your Total Delegation Investment
              </div>
              <div className="panel-block is-justify-content-center">
                {accountDelegationData.totalAccountDelegationsFormatted} OLT
              </div>
            </nav>
          </div>
        </div>

        {explorerMetrics.chartData.length > 0 && (
          <div className="columns">
            <div className="column">
              <h5 className="title is-5">Total Amount Delegated</h5>
              <DelegationChart data={explorerMetrics.chartData} />
            </div>
          </div>
        )}

        <div className="columns">
          {getDelegationAccountCards(
            accountDelegationData.accountDelegationStats,
          )}
        </div>
      </div>
    </section>
  );
};

MainPage.propTypes = {
  accountDelegationData: PropTypes.object,
  explorerMetrics: PropTypes.object,
  loadMainPageState: PropTypes.func,
};

export default connect(
  (state) => ({
    accountDelegationData: state.delegation.accountDelegationData,
    explorerMetrics: state.delegation.explorerMetrics,
  }),
  (dispatch) => ({
    ...bindActionCreators({ loadMainPageState }, dispatch),
  }),
)(MainPage);
