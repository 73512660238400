import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer } from 'react-notifications';
import '../styles/dist/notifications.css';

import { startOneWalletHandshake } from '../store/actions/delegationActions';

const AppInit = (props) => {
  const {
    isOneWalletHandshakeDone,
    isLoading,
    startOneWalletHandshake,
    children,
  } = props;

  useEffect(() => {
    startOneWalletHandshake();
  }, [startOneWalletHandshake]);

  return (
    <Fragment>
      {isOneWalletHandshakeDone && children}

      <div className={`global-spinner ${isLoading && 'is-active'}`}>
        <img
          className="fa-spinner"
          src="/images/loadingSpinner.svg"
          alt="Loading..."
        />
      </div>
      <NotificationContainer />
    </Fragment>
  );
};

AppInit.propTypes = {
  isOneWalletHandshakeDone: PropTypes.bool,
  isLoading: PropTypes.bool,
  startOneWalletHandshake: PropTypes.func,
};

export default connect(
  (state) => ({
    isOneWalletHandshakeDone: state.delegation.isOneWalletHandshakeDone,
    isLoading: state.delegation.isLoading,
  }),
  (dispatch) => ({
    ...bindActionCreators({ startOneWalletHandshake }, dispatch),
  }),
)(AppInit);
