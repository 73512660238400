import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import MainPage from '../../pages/MainPage/MainPage';
import AccountPage from '../../pages/AccountPage/AccountPage';

export default function AppRouter(props) {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true} component={MainPage} />
        <Route path="/account/:address" exact={true} component={AccountPage} />
        <Route render={() => <div>Ops...</div>} />
      </Switch>
    </BrowserRouter>
  );
}
