import React from 'react';
import '../../../styles/delegationChart.scss';
import { ResponsiveLine } from '@nivo/line';

const DelegationChart = (props) => {
  function getTooltipForEachItem(item) {
    const { xFormatted: date, amountFormatted } = item.point.data;
    return (
      <div className="chart-tooltip">
        {date} : <span className="chart-y-value"> {amountFormatted} OLT </span>
      </div>
    );
  }

  const { data } = props;

  return (
    <div className="delegation-chart">
      <ResponsiveLine
        data={data}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          precision: 'day',
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{
          type: 'linear',
        }}
        axisLeft={{
          legend: 'Total Amount Delegated',
          legendOffset: -70,
          legendPosition: 'middle',
        }}
        axisBottom={{
          format: '%b',
          tickValues: 'every month',
        }}
        curve="monotoneX"
        colors={['#00d1b2']}
        useMesh={true}
        margin={{ top: 20, right: 20, bottom: 60, left: 80 }}
        animate={true}
        pointSize={0}
        enableArea={true}
        enableGridX={false}
        tooltip={getTooltipForEachItem}
      />
    </div>
  );
};

export default DelegationChart;
