import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import '../../styles/AccountPage.scss';
import {
  showTransactionModal,
  loadAccountHistoryState,
} from '../../store/actions/delegationActions';
import AccountTransactionHistoryTable from './views/AccountTransactionHistoryTable';
import { useHistory } from 'react-router';
import TransactionModal from '../../components/common/TransactionModal';
import { txOfflineTypes } from '../../services/delegationAppService';

const AccountPage = (props) => {
  const history = useHistory();
  const {
    accountDelegationData,
    isTransactionModalVisible,
    showTransactionModal,
    loadAccountHistoryState,
    match: { params },
  } = props;

  const delegationData = accountDelegationData.accountDelegationStats.find(
    (stat) => stat.delegationAccount.address === params.address,
  );

  const [isDelegationBtnLoading, setIsDelegationBtnLoading] = useState(false);
  const [isUndelegationBtnLoading, setIsUndelegationBtnLoading] = useState(
    false,
  );
  const [isReinvestBtnLoading, setIsReinvestBtnLoading] = useState(false);
  const [isWithdrawBtnLoading, setIsWithdrawBtnLoading] = useState(false);

  function getShowTransactionModalMethod(mode, loadingStateFunc) {
    return async (e) => {
      loadingStateFunc(true);
      await showTransactionModal(mode, delegationData);
      loadingStateFunc(false);
    };
  }

  function getAccountDropdownItems() {
    return accountDelegationData.accountDelegationStats.map((stat) => {
      const address = stat.delegationAccount.address;

      return (
        <option key={address} value={address}>
          {stat.delegationAccount.accountName}
          &nbsp;({stat.delegationAccount.balanceFormatted} OLT)
        </option>
      );
    });
  }

  function goToHomePage(e) {
    e.preventDefault();
    history.push('/');
  }

  async function selectAccount(e) {
    const address = e.target.value;
    await loadAccountHistoryState(address);
    history.push(`/account/${address}`);
  }
  return (
    <section className="section delegation-page-section">
      <div className="container is-widescreen">
        <h1 className="title">
          Delegation App - {delegationData.delegationAccount.accountName}
        </h1>

        <nav className="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
              <a href="#" onClick={goToHomePage}>
                Home
              </a>
            </li>
            <li className="is-active">
              <a href="#">
                Account ({delegationData.delegationAccount.accountName})
              </a>
            </li>
          </ul>
        </nav>

        <div className="columns mt-4 is-desktop">
          <div className="column is-one-third">
            <div className="field">
              <div className="control has-icons-left">
                <div className="select is-primary is-fullwidth">
                  <select onChange={selectAccount} value={params.address}>
                    {getAccountDropdownItems()}
                  </select>
                </div>
                <div className="icon is-small is-left">
                  <i className="fas fa-user"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <table className="table is-rounded account-details">
              <tbody>
                <tr>
                  <td className="delegation-label primary-row-data rounded-row-data has-text-weight-bold">
                    Delegations
                  </td>
                  <td
                    className="amount secondary-row-data"
                    title={delegationData.delegationActiveFormatted + ' OLT'}
                  >
                    <span className="formatted-amount">
                      {delegationData.delegationActiveFormatted}
                      &nbsp;OLT
                    </span>
                  </td>
                  <td className="actions secondary-row-data">
                    {!!+delegationData.delegationAccount.balance && (
                      <button
                        type="button"
                        className={`button brand-btn is-rounded is-small
                         ${isDelegationBtnLoading ? 'is-loading' : ''}`}
                        title="Delegate"
                        onClick={getShowTransactionModalMethod(
                          txOfflineTypes.delegate,
                          setIsDelegationBtnLoading,
                        )}
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                    )}
                    <span>&nbsp;</span>
                    {!!+delegationData.delegationActive && (
                      <button
                        type="button"
                        className={`button brand-btn is-rounded is-small
                         ${isUndelegationBtnLoading ? 'is-loading' : ''}`}
                        title="Un-Delegate"
                        onClick={getShowTransactionModalMethod(
                          txOfflineTypes.undelegate,
                          setIsUndelegationBtnLoading,
                        )}
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                    )}
                  </td>
                  <td className="rewards-label primary-row-data rounded-row-data has-text-weight-bold">
                    Rewards
                  </td>
                  <td
                    className="amount secondary-row-data"
                    title={
                      delegationData.delegationRewardsActiveFormatted + ' OLT'
                    }
                  >
                    <span className="formatted-amount">
                      {delegationData.delegationRewardsActiveFormatted}
                      &nbsp;OLT
                    </span>
                  </td>
                  <td className="actions secondary-row-data">
                    {!!+delegationData.delegationRewardsActive && (
                      <>
                        <button
                          type="button"
                          className={`button brand-btn is-rounded is-small
                           ${isReinvestBtnLoading ? 'is-loading' : ''}`}
                          title="Reinvest Rewards"
                          onClick={getShowTransactionModalMethod(
                            txOfflineTypes.rewardReinvest,
                            setIsReinvestBtnLoading,
                          )}
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                        &nbsp;
                        <button
                          type="button"
                          className={`button brand-btn is-rounded is-small
                           ${isWithdrawBtnLoading ? 'is-loading' : ''}`}
                          title="Withdraw Rewards"
                          onClick={getShowTransactionModalMethod(
                            txOfflineTypes.rewardWithdrawal,
                            setIsWithdrawBtnLoading,
                          )}
                        >
                          <i className="fas fa-minus"></i>
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <AccountTransactionHistoryTable />
          </div>
        </div>
      </div>

      {isTransactionModalVisible && <TransactionModal />}
    </section>
  );
};

AccountPage.propTypes = {
  accountDelegationData: PropTypes.object,
  isTransactionModalVisible: PropTypes.bool,
  showTransactionModal: PropTypes.func,
  loadAccountHistoryState: PropTypes.func,
};

export default connect(
  (state) => ({
    accountDelegationData: state.delegation.accountDelegationData,
    isTransactionModalVisible: state.delegation.transactionModal.isModalVisible,
  }),
  (dispatch) => ({
    ...bindActionCreators(
      {
        showTransactionModal,
        loadAccountHistoryState,
      },
      dispatch,
    ),
  }),
)(AccountPage);
