const delegation = {
  isLoading: true,
  transactionModal: {},
  accountDelegationData: null,
  explorerMetrics: null,
  accountHistoryPageDetails: null,
  curAccountHistoryPageDetails: null,
};

export default delegation;
