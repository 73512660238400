import React from 'react';
import { Provider } from 'react-redux';
import AppRouter from './router/AppRouter';
import configureStore from '../store/configureStore';
import AppInit from './AppInit';

const store = configureStore();

const App = () => (
  <Provider store={store}>
    <AppInit>
      <AppRouter />
    </AppInit>
  </Provider>
);

export default App;
