import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import DataTable from 'react-data-table-component';
import '../../../styles/AccountTransactionHistoryTable.scss';
import { loadAccountHistoryState } from '../../../store/actions/delegationActions';
import { formatNumber } from '../../../services/utils';
import { explorerUiUrl } from '../../../constants/onsSdk';

const txTypeColumnNameMap = {
  ADD_NETWORK_DELEGATION: 'Delegate',
  NETWORK_UNDELEGATE: 'Un-Delegate',
  REWARDS_REINVEST_NETWORK_DELEGATE: 'Reinvest',
  REWARDS_WITHDRAW_NETWORK_DELEGATE: 'Withdraw',
};

function valueFormatter({ txValue }) {
  const txValueSplit = txValue.split(' ');
  const num = txValueSplit[0];
  const numFormatted = formatNumber(num);
  const currency = txValueSplit[1];

  return `${numFormatted} ${currency}`;
}

function txHashCellFormatter({ txHash }) {
  const txHashFirstPart = txHash.slice(0, 6);
  const txHashSecondPart = txHash.slice(txHash.length - 4);
  const display = txHashFirstPart + '...' + txHashSecondPart;

  return (
    <a
      href={`${explorerUiUrl}/transaction/${txHash}`}
      title={`${txHash}`}
      target="_blank"
      rel="noreferrer"
    >
      {display}
    </a>
  );
}

const AccountTransactionHistoryTable = (props) => {
  const {
    curPage,
    totalPages,
    accountAddress,
    history,
    loadAccountHistoryState,
  } = props;
  const columns = [
    {
      name: 'TX HASH',
      selector: 'txHash',
      sortable: true,
      cell: txHashCellFormatter,
    },
    {
      name: 'TYPE',
      selector: 'txType',
      sortable: true,
      format: ({ txType }) => txTypeColumnNameMap[txType],
    },
    {
      name: 'VALUE',
      selector: 'txValue',
      sortable: true,
      format: valueFormatter,
    },
    {
      name: 'FEE',
      selector: 'totalFee',
      sortable: true,
    },
    {
      name: 'GAS',
      selector: 'gasUsed',
      sortable: true,
      format: ({ gasUsed }) => formatNumber(gasUsed),
    },
  ];

  function getPaginationBtns() {
    return Array(totalPages)
      .fill()
      .map((val, index) => (
        <button
          className={`button pagination-btn ${
            curPage === index && 'is-active'
          }`}
          onClick={() => loadAccountHistoryState(accountAddress, index)}
          key={index}
        >
          {index + 1}
        </button>
      ));
  }

  return (
    <>
      <DataTable
        title="Transaction History"
        columns={columns}
        data={history}
        striped={true}
        highlightOnHover={true}
        responsive={true}
        className="account-transaction-history-table"
      />

      <br />
      <div className="columns">
        <div className="column is-2">
          <button
            className="button brand-btn is-rounded"
            disabled={curPage === 0 && 'disabled'}
            onClick={() => loadAccountHistoryState(accountAddress, curPage - 1)}
          >
            Previous
          </button>
        </div>
        <div className="column has-text-centered">{getPaginationBtns()}</div>
        <div className="column is-2 has-text-right">
          <button
            className="button brand-btn is-rounded"
            disabled={
              (!history.length || curPage === totalPages - 1) && 'disabled'
            }
            onClick={() => loadAccountHistoryState(accountAddress, curPage + 1)}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

AccountTransactionHistoryTable.propTypes = {
  curPage: PropTypes.number,
  totalPages: PropTypes.number,
  accountAddress: PropTypes.string,
  history: PropTypes.array,
  loadAccountHistoryState: PropTypes.func,
};

export default connect(
  (state) => ({
    curPage: state.delegation.curAccountHistoryPageDetails.curPage,
    totalPages: state.delegation.curAccountHistoryPageDetails.totalPages,
    accountAddress:
      state.delegation.curAccountHistoryPageDetails.accountAddress,
    history: state.delegation.curAccountHistoryPageDetails.history,
  }),
  (dispatch) => ({
    ...bindActionCreators({ loadAccountHistoryState }, dispatch),
  }),
)(AccountTransactionHistoryTable);
