import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as _ from 'lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { NotificationManager } from 'react-notifications';
import { formatNumber, toFixedNoRounding } from '../../services/utils';
import { txOfflineTypes } from '../../services/delegationAppService';
import {
  submitTransactionModal,
  submitTransactionModalSuccess,
  cancelTransactionModal,
  loadMainPageState,
} from '../../store/actions/delegationActions';
import { getNumberInputFieldMaxFractionDigitsStep } from '../../constants/app';

const txTypeLabelsMap = {
  [txOfflineTypes.delegate]: {
    action: 'Delegate',
    mainField: 'Balance',
  },
  [txOfflineTypes.undelegate]: {
    action: 'Un-Delegate',
    mainField: 'Delegation Stake',
  },
  [txOfflineTypes.rewardReinvest]: {
    action: 'Reinvest',
    mainField: 'Rewards',
  },
  [txOfflineTypes.rewardWithdrawal]: {
    action: 'Withdraw',
    mainField: 'Rewards',
  },
};

const TransactionModal = (props) => {
  const {
    isModalVisible,
    mode,
    maxAmount,
    fee,
    account,
    submitTransactionModal,
    submitTransactionModalSuccess,
    cancelTransactionModal,
    loadMainPageState,
    onSubmit,
  } = props;

  const isDelegationMode = mode === 'delegate';

  const maxAmountFormatted = formatNumber(maxAmount);

  let maxAmountMinusFees = maxAmount;

  if (isDelegationMode) {
    maxAmountMinusFees = maxAmount - fee;
  }

  const modeLabels = txTypeLabelsMap[mode];
  const mainFieldLabel = modeLabels.mainField;
  const amountRangeErrMsg = `Amount must be greater than 0 and less than or equal to ${mainFieldLabel}: ${maxAmountMinusFees}`;
  const validationSchema = yup.object().shape({
    amount: yup
      .number()
      .required()
      .typeError('amount is a required field')
      .moreThan(0, amountRangeErrMsg)
      .max(maxAmountMinusFees, amountRangeErrMsg),
    password: yup.string().required(),
  });
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [total, setTotal] = useState(0);
  const [isAmountInvalid, setIsAmountInvalid] = useState(false);
  const [isSubmitBtnLoading, setIsSubmitBtnLoading] = useState(false);
  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  function updateTotal(curAmount) {
    if (curAmount === maxAmount) {
      // To account for user clicking the `Max` button
      setTotal(maxAmountFormatted);
      return;
    }

    if (
      curAmount <= 0 ||
      curAmount > maxAmountMinusFees ||
      Number.isNaN(curAmount)
    ) {
      setIsAmountInvalid(true);
      setTotal(0);
      return;
    }

    let temoraryTotal = formatNumber(curAmount);

    if (isDelegationMode) {
      temoraryTotal = formatNumber(curAmount + fee);
    }

    setTotal(temoraryTotal);
    setIsAmountInvalid(false);
  }

  function setAmountToMax() {
    updateTotal(maxAmount);
    setValue('amount', toFixedNoRounding(maxAmountMinusFees));
  }

  function selectAllAmount(e) {
    e.target.select();
  }

  function togglePasswordVisibility() {
    setPasswordVisibility(!isPasswordVisible);
  }

  async function submitForm({ amount, password }) {
    setIsSubmitBtnLoading(true);

    try {
      await submitTransactionModal(mode, account, amount, password);

      NotificationManager.success(
        'Submitted successfully. The transaction is being processed and your balance will be updated shortly',
        ' ',
        5000,
      );

      // We need to refresh the main page state to get new account balances and
      // chart data
      await loadMainPageState();

      if (_.isFunction(onSubmit)) {
        await onSubmit();
      }
    } catch (e) {
      NotificationManager.error(e.payload.message);
      setIsSubmitBtnLoading(false);
      return;
    }
    setIsSubmitBtnLoading(false);
    submitTransactionModalSuccess();

    setTimeout(async () => {
      await loadMainPageState();
    }, 15000);
  }

  return (
    <div
      className={`modal delegation-app-modal ${
        isModalVisible ? 'is-active' : ''
      }`}
    >
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              {modeLabels.action}: {account.accountName}
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={cancelTransactionModal}
            ></button>
          </header>
          <section className="modal-card-body">
            <div className="field is-horizontal">
              <div className="field-label">
                <div className="label">{mainFieldLabel}</div>
              </div>
              <div className="field-body">
                <div className="field">{maxAmountFormatted} OLT</div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">*Amount</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="field has-addons">
                    <div className="control is-expanded">
                      <input
                        type="number"
                        min="0"
                        step={getNumberInputFieldMaxFractionDigitsStep()}
                        name="amount"
                        className="input"
                        onFocus={selectAllAmount}
                        onChange={(e) =>
                          updateTotal(parseFloat(e.target.value))
                        }
                        ref={register}
                      />
                    </div>
                    <div className="control">
                      <button
                        type="button"
                        className="button is-info"
                        title="Click to use maximum amount allowed"
                        onClick={setAmountToMax}
                      >
                        Max
                      </button>
                    </div>
                  </div>
                  <p className="help is-danger">
                    {errors.amount?.message || isAmountInvalid
                      ? amountRangeErrMsg
                      : ''}
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">*Password</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control is-expanded has-icons-right has-pointer-events-icon">
                    <input
                      type={isPasswordVisible ? 'text' : 'password'}
                      name="password"
                      className="input"
                      ref={register}
                    />
                    <span
                      className={`icon is-small is-right fa ${
                        isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'
                      }`}
                      onClick={togglePasswordVisibility}
                    />
                  </div>
                  <p className="help is-danger">{errors.password?.message}</p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label">
                <div className="label">Fees</div>
              </div>
              <div className="field-body">
                <div className="field">{fee} OLT</div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label">
                <div className="label">Total</div>
              </div>
              <div className="field-body">
                <div className="field">{total} OLT</div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button
              type="button"
              className="button action-button is-danger is-rounded"
              onClick={cancelTransactionModal}
            >
              Cancel
            </button>
            <button
              className={`button is-success is-rounded
              ${isSubmitBtnLoading ? 'is-loading' : ''}`}
              type="submit"
            >
              {modeLabels.action}
            </button>
          </footer>
        </div>
      </form>
    </div>
  );
};

TransactionModal.propTypes = {
  isModalVisible: PropTypes.bool,
  mode: PropTypes.string,
  maxAmount: PropTypes.number,
  fee: PropTypes.number,
  accountBalance: PropTypes.number,
  account: PropTypes.object,
  submitTransactionModal: PropTypes.func,
  submitTransactionModalSuccess: PropTypes.func,
  cancelTransactionModal: PropTypes.func,
  loadMainPageState: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default connect(
  (state) => {
    const { transactionModal } = state.delegation;
    return {
      isModalVisible: transactionModal.isModalVisible,
      mode: transactionModal.mode,
      maxAmount: transactionModal.maxAmount,
      fee: transactionModal.fee,
      accountBalance: transactionModal.accountBalance,
      account: transactionModal.account,
    };
  },
  (dispatch) => ({
    ...bindActionCreators(
      {
        submitTransactionModal,
        submitTransactionModalSuccess,
        cancelTransactionModal,
        loadMainPageState,
      },
      dispatch,
    ),
  }),
)(TransactionModal);
