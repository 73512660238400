import { maxFractionDigits } from '../constants/app';

/**
 * Return formatted number with comma separator for thousands and no rounding
 * for decimals
 * @param num
 * @param fractionDigits defaults to 6
 * @returns String
 */
export const formatNumber = (num, fractionDigits = maxFractionDigits) => {
  const [integer, fraction] = num.toString().split('.');
  const formattedInteger = Intl.NumberFormat('en-US').format(integer);
  const truncatedFraction = fraction
    ? `.${fraction.slice(0, fractionDigits)}`
    : '';
  return formattedInteger + truncatedFraction;
};

export const toFixedNoRounding = (num, fractionDigits = maxFractionDigits) => {
  const [integer, fraction] = num.toString().split('.');
  const truncatedFraction = fraction
    ? `.${fraction.slice(0, fractionDigits)}`
    : '';
  return parseFloat(integer + truncatedFraction);
};
