import * as _ from 'lodash';
import * as types from '../types/delegationTypes';
import getOneWalletService from '../../services/oneWalletService';
import {
  getMainPageState,
  getAccountsDelegationState,
  getAccountHistory,
  txOfflineTypes,
  getTxOfflineByType,
} from '../../services/delegationAppService';

const finishOneWalletHandshake = () => ({
  type: types.FINISH_ONE_WALLET_HANDSHAKE,
});

export const startOneWalletHandshake = () => {
  return async (dispatch) => {
    dispatch({ type: types.START_ONE_WALLET_HANDSHAKE });
    const oneWallet = getOneWalletService();

    await oneWallet.startHandshake();

    dispatch(finishOneWalletHandshake());
  };
};

export const loadMainPageState = () => {
  return async (dispatch) => {
    dispatch({ type: types.LOAD_MAIN_PAGE_STATE });

    const mainPageState = await getMainPageState();

    dispatch({
      type: types.LOAD_MAIN_PAGE_STATE_SUCCESS,
      mainPageState,
    });
  };
};

export const loadAccountsDelegationState = () => {
  return async (dispatch) => {
    dispatch({ type: types.LOAD_ACCOUNTS_DELEGATION_STATE });

    const accountsDelegationState = await getAccountsDelegationState();

    dispatch({
      type: types.LOAD_ACCOUNTS_DELEGATION_STATE_SUCCESS,
      accountsDelegationState,
    });
  };
};

export const loadAccountHistoryState = (accountAddress, page = 0) => {
  return async (dispatch) => {
    dispatch({ type: types.LOAD_ACCOUNT_HISTORY_STATE });

    const accountHistoryPageDetails = await getAccountHistory(
      accountAddress,
      page,
    );

    dispatch({
      type: types.LOAD_ACCOUNT_HISTORY_STATE_SUCCESS,
      accountHistoryPageDetails,
    });

    return dispatch(loadAccountsDelegationState());
  };
};

const txModeMaxAmountPropMap = {
  [txOfflineTypes.delegate]: 'delegationAccount.balance',
  [txOfflineTypes.undelegate]: 'delegationActive',
  [txOfflineTypes.rewardReinvest]: 'delegationRewardsActive',
  [txOfflineTypes.rewardWithdrawal]: 'delegationRewardsActive',
};

export const showTransactionModal = (mode, delegationData) => {
  return async (dispatch) => {
    dispatch({ type: types.SHOW_TRANSACTION_MODAL });

    const account = delegationData.delegationAccount;
    // We pass any amount "1" as we are concerned only about getting
    // the fee to set off initial calculations inside the modal
    const {
      response: {
        feeEstimation: { value: fee },
      },
    } = await getTxOfflineByType(mode, account.address, 1);

    const accountBalance = parseFloat(
      _.get(delegationData, txModeMaxAmountPropMap[txOfflineTypes.delegate]),
    );

    const transactionModal = {
      isModalVisible: true,
      mode,
      maxAmount: parseFloat(
        _.get(delegationData, txModeMaxAmountPropMap[mode]),
      ),
      accountBalance,
      fee: parseFloat(fee),
      account,
    };

    dispatch({
      type: types.SHOW_TRANSACTION_MODAL_SUCCESS,
      transactionModal,
    });
  };
};

export const submitTransactionModal = (mode, account, amount, password) => {
  return async (dispatch) => {
    dispatch({ type: types.SUBMIT_TRANSACTION_MODAL });

    const {
      response: { rawTx },
    } = await getTxOfflineByType(mode, account.address, amount);

    let { keyType, keyIndex, publicKey } = account;
    // We only deal with OLTs for now
    keyType = 'OLT';

    await getOneWalletService().signTx(
      rawTx,
      password,
      keyType,
      keyIndex,
      publicKey,
    );
  };
};

export const submitTransactionModalSuccess = () => ({
  type: types.SUBMIT_TRANSACTION_MODAL_SUCCESS,
});

export const cancelTransactionModal = () => ({
  type: types.CANCEL_TRANSACTION_MODAL,
});
